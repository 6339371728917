
import http from "@/_helper/api-services.js";

export default {
    name: "Assitir",
    data() {
        return {
            anuncio: {
                titulo: String(),
                descricao: String(),
                urlVideo: String()
            }
        };
    },
    mounted() {
        this.$loading(true);
        if (this.$route.params.idAnuncio) {
            http.get("/adverts/assistir/" + this.$route.params.idAnuncio).then(success => {
                if (success.data)
                    this.anuncio = success.data;

                this.$loading(false);
            });
        }
    }
}